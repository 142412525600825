<template>
  <section>
    <h6 class="mt-3">{{ $t('hotspot.network') }}</h6>

    <div class="form-group">
      <label for="network_type">{{ $t('hotspot.networkType') }}</label>
      <select
        v-model="hotspotData.net_type"
        name="network_type"
        id="network_type"
        class="form-control"
        :class="{ 'select-disabled': isDisable }"
        :disabled="isDisable"
      >
        <option v-for="(type, key) in hotspotService.ACCESS_NETWORK_TYPE" :value="+key">{{ type.name }}</option>
      </select>
    </div>

    <div class="form-group mt-1">
      <Switch-component
        v-model="hotspotData.internet"
        :disabled="isDisable"
        :label="$t('hotspot.internet')"
        :id="'hotspot-internet'"
      />
    </div>

    <div class="form-group mt-1">
      <Switch-component
        v-model="hotspotData.asra"
        :disabled="isDisable"
        :label="$t('hotspot.additionalStep')"
        :id="'hotspot-asra'"
      />
    </div>

    <div class="form-group mt-1">
      <Switch-component
        v-model="hotspotData.esr"
        :disabled="isDisable"
        :label="$t('hotspot.emergencyServices')"
        :id="'hotspot-esr'"
      />
    </div>

    <div class="form-group mt-1">
      <Switch-component
        v-model="hotspotData.uesa"
        :disabled="isDisable"
        :label="$t('hotspot.emergencyServices')"
        :id="'hotspot-uesa'"
      />
    </div>

    <div class="form-group mt-1">
      <Switch-component
        v-model="hotspotData.dgaf"
        :disabled="isDisable"
        :label="$t('hotspot.dgaf')"
        :id="'hotspot-dgaf'"
      />
    </div>

    <h6 class="mt-3">{{ $t('hotspot.ipAvailability') }}</h6>

    <div class="form-group mb-h">
      <label for="ipv4_type">IPv4</label>
      <select
        v-model="hotspotData.ip_types.ipv4type"
        name="venue_group"
        id="ipv4_type"
        class="form-control"
        :class="{ 'select-disabled': isDisable }"
        :disabled="isDisable"
      >
        <option v-for="(group, key) in hotspotService.IPV4_TYPE" :value="+key">{{ group.name }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="ipv6_type">IPv6</label>
      <select
        v-model="hotspotData.ip_types.ipv6type"
        name="venue_group"
        id="ipv6_type"
        class="form-control"
        :class="{ 'select-disabled': isDisable }"
        :disabled="isDisable"
      >
        <option v-for="(group, key) in hotspotService.IPV6_TYPE" :value="+key">{{ group.name }}</option>
      </select>
    </div>

    <h6 class="mt-3">{{ $t('hotspot.venue') }}</h6>

    <div class="form-group">
      <label for="venue_group">{{ $t('hotspot.group') }}</label>
      <select
        v-model="hotspotData.venue.group"
        name="venue_group"
        id="venue_group"
        class="form-control"
        @change="resetVenueType"
        :class="{ 'select-disabled': isDisable }"
        :disabled="isDisable"
      >
        <option v-for="(group, key) in hotspotService.VENUE_GROUP" :value="+key">{{ group.name }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="venue_type">{{ $t('hotspot.type') }}</label>
      <select
        v-model="hotspotData.venue.type"
        :class="{ 'select-disabled': isDisable }"
        :disabled="isDisable"
        name="venue_type"
        id="venue_type"
        class="form-control"
      >
        <option v-for="(type, key) in hotspotService.VENUE_GROUP[hotspotData.venue.group].types" :value="+key">
          {{ type.name }}
        </option>
      </select>
    </div>

    <div class="row url-input-double">
      <div class="form-group mr-h mb-0">
        <label for="venue_name">{{ $t('general.name') }}</label>
        <input
          type="text"
          name="venue_name"
          class="form-control"
          :placeholder="$t('general.name')"
          id="venue_name"
          :disabled="isDisable"
          v-validate="'required'"
          data-vv-validate-on="input"
          @keyup.13="addVenueName"
          v-model="newVenueName.name"
          :class="{ input: true, 'is-danger': errors.has('venue_name') }"
        />
      </div>
      <div class="form-group mr-h mb-0">
        <label for="venue_type">{{ $t('hotspot.language') }}</label>
        <select
          v-model="newVenueName.lang"
          name="venue_language"
          id="venue_language"
          class="form-control"
          :class="{ 'select-disabled': isDisable }"
          :disabled="isDisable"
        >
<!--          <option v-for="lang in hotspotService.LANGUAGE" :value="lang.id">{{ lang.name }}</option>-->
          <option v-for="lang in sortedByNameLangList" :value="lang.id">{{ lang.name }}</option>
        </select>
      </div>
      <div class="">
        <WButton success
          customClass="mb-0"
          @click="addVenueName"
          :disabled="!newVenueName.name || !newVenueName.lang || errors.any()"
        >
          {{ $t('general.add') }}
        </WButton>
      </div>
    </div>
    <div class="items_list mb-1 mt-h">
      <ul class="list-unstyled">
        <li
          v-for="(name, index) in hotspotData.venue.names"
          @click="removeVenueName"
          :data-index="index"
          :class="{ 'item-for-delete--disable': isDisable }"
          class="item-for-delete ml-h mb-q"
        >
<!--          {{ name.name }}&nbsp;( {{ name.lang }} )-->
           {{ name.name }}&nbsp;( {{ getLangNameWithI18nById(name.lang) }} )
          <i class="fa fa-remove text-danger" v-if="!isDisable"></i>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import SwitchComponent from '../Universal/Switch-component.vue';

export default {
  name: 'Network',
  components: {
    SwitchComponent
  },
  inject: ['$validator'],
  props: {
    hotspotData: {
      type: Object
    },
    hotspotService: {
      type: Object
    },
    commonService: {
      type: Object
    },
    isDisable: {
      type: Boolean
    }
  },
  data() {
    return {
      newVenueName: {
        lang: 'eng',
        name: ''
      }
    };
  },
  computed: {
    sortedByNameLangList() {
      const unsortedLangs = JSON.parse(JSON.stringify(this.hotspotService.LANGUAGE))
      const compare = function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      const sortedLangs = unsortedLangs.sort(compare);
      return sortedLangs
    },
  },
  methods: {
    getLangNameWithI18nById(langId) {
      try {
        const langsList = JSON.parse(JSON.stringify(this.hotspotService.LANGUAGE))
        const langObj = langsList.find((lang) => {
          return lang.id === langId;
        })
        if (Object.keys(langObj).length && langObj.name) {
          return langObj.name;
        }
        return langId
      } catch (e) {
        console.log(e)
        return langId
      }
    },
    resetVenueType() {
      this.hotspotData.venue.type = 0;
    },
    addVenueName() {
      this.$validator
        .validateAll({ venue_name: this.newVenueName.name, venue_language: this.newVenueName.lang })
        .then((result) => {
          if (result) {
            this.hotspotData.venue.names.push({
              lang: this.newVenueName.lang,
              name: this.newVenueName.name
            });
            this.newVenueName.lang = 'eng';
            this.newVenueName.name = '';
            this.$validator.reset();
          }
        });
    },
    removeVenueName(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.hotspotData.venue.names.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped></style>
