/**
 * Миксин содержит различне computed списки, используемые в разделах hotspot
 *
 * Используется только в компонентах hotspot
 *
 * @mixin
 *
 */

import { createNamespacedHelpers } from 'vuex';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');


const listsForHotspotMixin = {
  computed: {
    ...localeMapGetters(['isRuLocale']),
    hotspotLists() {
      return {
        ACCESS_NETWORK_TYPE: {
          0: {
            name: this.isRuLocale ? 'Приватная сеть' : 'Private network'
          },
          1: {
            name: this.isRuLocale ? 'Приватная сеть с гостевым доступом' : 'Private network with guest access'
          },
          2: {
            name: this.isRuLocale ? 'Платная публичная сеть' : 'Chargeable public network'
          },
          3: {
            name: this.isRuLocale ? 'Бесплатная публичная сеть' : 'Free public network'
          },
          4: {
            name: this.isRuLocale ? 'Сеть на персональном устройстве' : 'Personal device network'
          },
          5: {
            name: this.isRuLocale ? 'Сеть только с экстренными  сервисами' : 'Emergency services only network'
          },
          14: {
            name: this.isRuLocale ? 'Тестовая или эксперементальная' : 'Test or experimental'
          },
          15: {
            name: 'Wildcard'
          }
        },

        VENUE_GROUP: {
          0: {
            name: this.isRuLocale ? 'Неопределенно' : 'Unspecified',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              }
            }
          },
          1: {
            name: this.isRuLocale ? 'Места массового пребывания людей' : 'Assembly',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              },
              1: {
                name: this.isRuLocale ? 'Арена' : 'Arena'
              },
              2: {
                name: this.isRuLocale ? 'Стадион' : 'Stadium'
              },
              3: {
                name: this.isRuLocale ? 'Пассажирский терминал' : 'Passenger terminal'
              },
              4: {
                name: this.isRuLocale ? 'Амфитеатр' : 'Amphitheater'
              },
              5: {
                name: this.isRuLocale ? 'Парк с аттракционами' : 'Amusement park'
              },
              6: {
                name: this.isRuLocale ? 'Место поклонения' : 'Place of worship'
              },
              7: {
                name: this.isRuLocale ? 'Конференц-центр' : 'Convention center'
              },
              8: {
                name: this.isRuLocale ? 'Библиотека' : 'Library'
              },
              9: {
                name: this.isRuLocale ? 'Музей' : 'Museum'
              },
              10: {
                name: this.isRuLocale ? 'Ресторан' : 'Restaurant'
              },
              11: {
                name: this.isRuLocale ? 'Театр' : 'Theater'
              },
              12: {
                name: this.isRuLocale ? 'Бар' : 'Bar'
              },
              13: {
                name: this.isRuLocale ? 'Кофейный магазин' : 'Coffee shop'
              },
              14: {
                name: this.isRuLocale ? 'Зоопарк или Аквариум' : 'Zoo or Aquarium'
              },
              15: {
                name: this.isRuLocale ? 'Центр координации чрезвычайных ситуаций' : 'Emergency coordination center'
              }
            }
          },
          2: {
            name: this.isRuLocale ? 'Бизнес' : 'Business',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              },
              1: {
                name: this.isRuLocale ? 'Кабинет врача или стоматолога' : 'Doctor or Dentist office'
              },
              2: {
                name: this.isRuLocale ? 'Банк' : 'Bank'
              },
              3: {
                name: this.isRuLocale ? 'Пожарная часть' : 'Fire station'
              },
              4: {
                name: this.isRuLocale ? 'Полиция' : 'Police station'
              },
              6: {
                name: this.isRuLocale ? 'Почта' : 'Post office'
              },
              7: {
                name: this.isRuLocale ? 'Офис' : 'Professional office'
              },
              8: {
                name: this.isRuLocale ? 'Научно-исследовательский центр' : 'Research and development facility'
              },
              9: {
                name: this.isRuLocale ? 'Адвокат' : 'Attorney office'
              }
            }
          },
          3: {
            name: this.isRuLocale ? 'Образование' : 'Educational',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              },
              1: {
                name: this.isRuLocale ? 'Начальная школа' : 'Primary school'
              },
              2: {
                name: this.isRuLocale ? 'Средняя школа' : 'Secondary school'
              },
              3: {
                name: this.isRuLocale ? 'Университет или колледж' : 'University or College'
              }
            }
          },
          4: {
            name: this.isRuLocale ? 'Промышленность' : 'Factory or Industrial',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              },
              1: {
                name: this.isRuLocale ? 'Фабрика' : 'Factory'
              }
            }
          },
          5: {
            name: this.isRuLocale ? 'Социальные объекты' : 'Institutional',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              },
              1: {
                name: this.isRuLocale ? 'Больница' : 'Hospital'
              },
              2: {
                name: this.isRuLocale
                  ? 'Учреждение длительного ухода'
                  : 'Long-Term Care Facility (e.g., Nursing home, Hospice, etc.)'
              },
              3: {
                name: this.isRuLocale ? 'Реабилитационный центр' : 'Alcohol and Drug Rehabilitation Center'
              },
              4: {
                name: this.isRuLocale ? 'Групповой дом' : 'Group home'
              },
              5: {
                name: this.isRuLocale ? 'Тюрьма' : 'Prison or Jail'
              }
            }
          },
          6: {
            name: this.isRuLocale ? 'Торговые объекты' : 'Mercantile',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              },
              1: {
                name: this.isRuLocale ? 'Розничный магазин' : 'Retail store'
              },
              2: {
                name: this.isRuLocale ? 'Продуктовый рынок' : 'Grocery market'
              },
              3: {
                name: this.isRuLocale ? 'Автосервис' : 'Automotive service station'
              },
              4: {
                name: this.isRuLocale ? 'Торговый центр' : 'Shopping mall'
              },
              5: {
                name: this.isRuLocale ? 'Автозаправочная станция' : 'Gas station'
              }
            }
          },
          7: {
            name: this.isRuLocale ? 'Проживание' : 'Residential',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              },
              1: {
                name: this.isRuLocale ? 'Частная резиденция' : 'Private residence'
              },
              2: {
                name: this.isRuLocale ? 'Отель или мотель' : 'Hotel or Motel'
              },
              3: {
                name: this.isRuLocale ? 'Общежитие' : 'Dormitory'
              },
              4: {
                name: this.isRuLocale ? 'Пансионат' : 'Boarding house'
              }
            }
          },
          8: {
            name: this.isRuLocale ? 'Хранилище' : 'Storage',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              }
            }
          },
          9: {
            name: this.isRuLocale ? 'Разное' : 'Utility and Miscellaneous',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              }
            }
          },
          10: {
            name: this.isRuLocale ? 'Транспорт' : 'Vehicular',
            types: {
              0: {
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              },
              1: {
                name: this.isRuLocale ? 'Автомобиль или грузовик' : 'Automobile or Truck'
              },
              2: {
                name: this.isRuLocale ? 'Самолет' : 'Airplane'
              },
              3: {
                name: this.isRuLocale ? 'Автобус' : 'Bus'
              },
              4: {
                name: this.isRuLocale ? 'Паром' : 'Ferry'
              },
              5: {
                name: this.isRuLocale ? 'Судно или лодка' : 'Ship or Boat'
              },
              6: {
                name: this.isRuLocale ? 'Поезд' : 'Train'
              },
              7: {
                name: this.isRuLocale ? 'Мотоцикл' : 'Motor Bike'
              }
            }
          },
          11: {
            name: this.isRuLocale ? 'Уличное пространство' : 'Outdoor',
            types: {
              0: {
                id: 0,
                name: this.isRuLocale ? 'Неопределенно' : 'Unspecified'
              },
              1: {
                id: 1,
                name: this.isRuLocale ? 'Муниципальная мэш сеть' : 'Muni-mesh Network'
              },
              2: {
                id: 2,
                name: this.isRuLocale ? 'Городской парк' : 'City park'
              },
              3: {
                id: 3,
                name: this.isRuLocale ? 'Зона отдыха' : 'Rest area'
              },
              4: {
                id: 4,
                name: this.isRuLocale ? 'Управление трафиком' : 'Traffic control'
              },
              5: {
                id: 5,
                name: this.isRuLocale ? 'Автобусная остановка' : 'Bus stop'
              },
              6: {
                id: 6,
                name: this.isRuLocale ? 'Киоск' : 'Kiosk'
              }
            }
          }
        },

        LANGUAGE: [
          {
            id: 'afr',
            name: this.isRuLocale ? 'Африкаанс' : 'Afrikaans'
          },
          {
            id: 'ara',
            name: this.isRuLocale ? 'Арабский' : 'Arabic'
          },
          {
            id: 'aze',
            name: this.isRuLocale ? 'Азербайджанский' : 'Azeri'
          },
          {
            id: 'bel',
            name: this.isRuLocale ? 'Белорусский' : 'Belarusian'
          },
          {
            id: 'bul',
            name: this.isRuLocale ? 'Болгарский' : 'Bulgarian'
          },
          {
            id: 'cat',
            name: this.isRuLocale ? 'Каталонский' : 'Catalan'
          },
          {
            id: 'cze',
            name: this.isRuLocale ? 'Чешский' : 'Czech'
          },
          {
            id: 'wel',
            name: this.isRuLocale ? 'Валлийский' : 'Welsh'
          },
          {
            id: 'dan',
            name: this.isRuLocale ? 'Датский' : 'Danish'
          },
          {
            id: 'ger',
            name: this.isRuLocale ? 'Немецкий' : 'German'
          },
          {
            id: 'div',
            name: this.isRuLocale ? 'Дивехи' : 'Divehi'
          },
          {
            id: 'gre',
            name: this.isRuLocale ? 'Греческий' : 'Greek'
          },
          {
            id: 'eng',
            name: this.isRuLocale ? 'Английский' : 'English'
          },
          {
            id: 'epo',
            name: this.isRuLocale ? 'Эсперанто' : 'Esperanto'
          },
          {
            id: 'spa',
            name: this.isRuLocale ? 'Испанский' : 'Spanish'
          },
          {
            id: 'est',
            name: this.isRuLocale ? 'Эстонский' : 'Estonian'
          },
          {
            id: 'eus',
            name: this.isRuLocale ? 'Баскский' : 'Basque'
          },
          {
            id: 'fas',
            name: this.isRuLocale ? 'Фарси' : 'Farsi'
          },
          {
            id: 'fin',
            name: this.isRuLocale ? 'Финский' : 'Finnish'
          },
          {
            id: 'fao',
            name: this.isRuLocale ? 'Фарерский' : 'Faroese'
          },
          {
            id: 'fre',
            name: this.isRuLocale ? 'Французский' : 'French'
          },
          {
            id: 'glg',
            name: this.isRuLocale ? 'Галисийский' : 'Galician'
          },
          {
            id: 'guj',
            name: this.isRuLocale ? 'Гуджарати' : 'Gujarati'
          },
          {
            id: 'heb',
            name: this.isRuLocale ? 'Иврит' : 'Hebrew'
          },
          {
            id: 'hin',
            name: this.isRuLocale ? 'Хинди' : 'Hindi'
          },
          {
            id: 'hrv',
            name: this.isRuLocale ? 'Хорватский' : 'Croatian'
          },
          {
            id: 'hun',
            name: this.isRuLocale ? 'Венгерский' : 'Hungarian'
          },
          {
            id: 'hye',
            name: this.isRuLocale ? 'Армянский' : 'Armenian'
          },
          {
            id: 'ind',
            name: this.isRuLocale ? 'Индонезийский' : 'Indonesian'
          },
          {
            id: 'isl',
            name: this.isRuLocale ? 'Исландский' : 'Icelandic'
          },
          {
            id: 'ita',
            name: this.isRuLocale ? 'Итальянский' : 'Italian'
          },
          {
            id: 'jpn',
            name: this.isRuLocale ? 'Японский' : 'Japanese'
          },
          {
            id: 'kat',
            name: this.isRuLocale ? 'Грузинский' : 'Georgian'
          },
          {
            id: 'kaz',
            name: this.isRuLocale ? 'Казахский' : 'Kazakh'
          },
          {
            id: 'kan',
            name: this.isRuLocale ? 'Каннада' : 'Kannada'
          },
          {
            id: 'kor',
            name: this.isRuLocale ? 'Корейский' : 'Korean'
          },
          {
            id: 'kok',
            name: this.isRuLocale ? 'Конкани' : 'Konkani'
          },
          {
            id: 'kir',
            name: this.isRuLocale ? 'Кыргызский' : 'Kyrgyz'
          },
          {
            id: 'lit',
            name: this.isRuLocale ? 'Литовский' : 'Lithuanian'
          },
          {
            id: 'lav',
            name: this.isRuLocale ? 'Латышский' : 'Latvian'
          },
          {
            id: 'mri',
            name: this.isRuLocale ? 'Маори' : 'Maori'
          },
          {
            id: 'mkd',
            name: this.isRuLocale ? 'Македонский' : 'FYRO Macedonian'
          },
          {
            id: 'mon',
            name: this.isRuLocale ? 'Монгольский' : 'Mongolian'
          },
          {
            id: 'mar',
            name: this.isRuLocale ? 'Маратхи' : 'Marathi'
          },
          {
            id: 'msa',
            name: this.isRuLocale ? 'Малайский' : 'Malay'
          },
          {
            id: 'mlt',
            name: this.isRuLocale ? 'Мальтийский' : 'Maltese'
          },
          {
            id: 'nid',
            name: this.isRuLocale ? 'Голландский' : 'Dutch'
          },
          {
            id: 'nso',
            name: this.isRuLocale ? 'Северный сото' : 'Northern Sotho'
          },
          {
            id: 'pan',
            name: this.isRuLocale ? 'Панджаби' : 'Punjabi'
          },
          {
            id: 'pol',
            name: this.isRuLocale ? 'Польский' : 'Polish'
          },
          {
            id: 'pus',
            name: this.isRuLocale ? 'Пушту' : 'Pashto'
          },
          {
            id: 'por',
            name: this.isRuLocale ? 'Португальский' : 'Portuguese'
          },
          {
            id: 'que',
            name: this.isRuLocale ? 'Кечуа' : 'Quechua'
          },
          {
            id: 'ron',
            name: this.isRuLocale ? 'Румынский' : 'Romanian'
          },
          {
            id: 'rus',
            name: this.isRuLocale ? 'Русский' : 'Russian'
          },
          {
            id: 'san',
            name: this.isRuLocale ? 'Санскрит' : 'Sanskrit'
          },
          {
            id: 'sme',
            name: this.isRuLocale ? 'Саамский' : 'Sami'
          },
          {
            id: 'slk',
            name: this.isRuLocale ? 'Словацкий' : 'Slovak'
          },
          {
            id: 'slv',
            name: this.isRuLocale ? 'Словенский' : 'Slovenian'
          },
          {
            id: 'sqi',
            name: this.isRuLocale ? 'Албанский' : 'Albanian'
          },
          {
            id: 'swe',
            name: this.isRuLocale ? 'Шведский' : 'Swedish'
          },
          {
            id: 'swa',
            name: this.isRuLocale ? 'Суахили' : 'Swahili'
          },
          {
            id: 'syr',
            name: this.isRuLocale ? 'Сирийский' : 'Syriac'
          },
          {
            id: 'tam',
            name: this.isRuLocale ? 'Тамильский' : 'Tamil'
          },
          {
            id: 'tel',
            name: this.isRuLocale ? 'Телугу' : 'Telugu'
          },
          {
            id: 'tha',
            name: this.isRuLocale ? 'Тайский' : 'Thai'
          },
          {
            id: 'tgl',
            name: this.isRuLocale ? 'Тагальский' : 'Tagalog'
          },
          {
            id: 'tsn',
            name: this.isRuLocale ? 'Тсвана' : 'Tswana'
          },
          {
            id: 'tur',
            name: this.isRuLocale ? 'Турецкий' : 'Turkish'
          },
          {
            id: 'tat',
            name: this.isRuLocale ? 'Татарский' : 'Tatar'
          },
          {
            id: 'tso',
            name: this.isRuLocale ? 'Цонга' : 'Tsonga'
          },
          {
            id: 'ukr',
            name: this.isRuLocale ? 'Украинский' : 'Ukrainian'
          },
          {
            id: 'urd',
            name: this.isRuLocale ? 'Урду' : 'Urdu'
          },
          {
            id: 'uzb',
            name: this.isRuLocale ? 'Узбекский' : 'Uzbek'
          },
          {
            id: 'vie',
            name: this.isRuLocale ? 'Вьетнамский' : 'Vietnamese'
          },
          {
            id: 'xho',
            name: this.isRuLocale ? 'Коса' : 'Xhosa'
          },
          {
            id: 'zho',
            name: this.isRuLocale ? 'Китайский' : 'Chinese'
          },
          {
            id: 'zul',
            name: this.isRuLocale ? 'Зулу' : 'Zulu'
          }
        ],

        EAP_METHODS: {
          13: {
            name: 'EAP-TLS'
          },
          18: {
            name: 'EAP-SIM'
          },
          21: {
            name: 'EAP-TTLS'
          },
          23: {
            name: 'EAP-AKA'
          },
          50: {
            name: "EAP-AKA'"
          }
        },

        EAP_TYPE: {
          2: {
            name: this.isRuLocale ? 'Внутренняя аутентификация без EAP' : 'Non-EAP Inner Authentication',
            subtype: {
              1: {
                name: 'PAP'
              },
              2: {
                name: 'CHAP'
              },
              3: {
                name: 'MSCHAP'
              },
              4: {
                name: 'MSCHAPv2'
              }
            }
          },
          3: {
            name: this.isRuLocale ? 'EAP метод внутренней аутентификации' : 'Inner Authentication EAP Method',
            subtype: {
              0: {
                name: this.isRuLocale ? 'Нет' : 'None'
              }
            }
          },
          5: {
            name: this.isRuLocale ? 'Учетные данные' : 'Credential',
            subtype: {
              1: {
                name: this.isRuLocale ? 'SIM карта' : 'SIM'
              },
              2: {
                name: this.isRuLocale ? 'USIM карта' : 'USIM'
              },
              3: {
                name: this.isRuLocale ? 'NFC' : 'NFC Secure Element'
              },
              4: {
                name: this.isRuLocale ? 'Аппаратный токен' : 'Hardware Token'
              },
              5: {
                name: this.isRuLocale ? 'Программный токен' : 'Softoken'
              },
              6: {
                name: this.isRuLocale ? 'Сертификат' : 'Certificate'
              },
              7: {
                name: this.isRuLocale ? 'Имя пользователя/Пароль' : 'Username/Password'
              },
              8: {
                name: this.isRuLocale ? 'Анонимно' : 'Anonymous'
              },
              9: {
                name: this.isRuLocale ? 'Специфичный для производителя' : 'Vendor Specific'
              }
            }
          }
        },

        IPV4_TYPE: {
          '-1': {
            name: this.isRuLocale ? 'Нет' : 'None'
          },
          0: {
            name: this.isRuLocale ? 'Тип IPv4 недоступен' : 'Address type not available'
          },
          1: {
            name: this.isRuLocale ? 'Доступен публичный IPv4-адрес' : 'Public IPv4 address available'
          },
          2: {
            name: this.isRuLocale
              ? 'Доступен IPv4-адрес с ограничением по портам'
              : 'Port-restricted IPv4 address available'
          },
          3: {
            name: this.isRuLocale
              ? 'Доступен частный IPv4-адрес c одинарным NAT'
              : 'Single NATed private IPv4 address available'
          },
          4: {
            name: this.isRuLocale
              ? 'Доступен частный IPv4-адрес с двойным NAT'
              : 'Double NATed private IPv4 address available'
          },
          5: {
            name: this.isRuLocale
              ? 'Доступны IPv4-адрес с ограничением по портам и одиночный NAT-адрес IPv4'
              : 'Port-restricted IPv4 address and single NATed IPv4 address available'
          },
          6: {
            name: this.isRuLocale
              ? 'Доступны IPv4-адрес с ограничением по портам и двойым NAT'
              : 'Port-restricted IPv4 address and double NATed IPv4 address available'
          },
          7: {
            name: this.isRuLocale
              ? 'Доступность типа адреса не известна'
              : 'Availability of the address type is not known'
          }
        },

        IPV6_TYPE: {
          '-1': {
            name: this.isRuLocale ? 'Нет' : 'None'
          },
          0: {
            name: this.isRuLocale ? 'Тип адреса недоступен' : 'Address type not available'
          },
          1: {
            name: this.isRuLocale ? 'Тип адреса доступен' : 'Address type available'
          },
          2: {
            name: this.isRuLocale ? 'Доступность типа адреса не известна' : 'Availability of the address type not known'
          }
        },

        IP_PROTOCOLS: {
          1: {
            name: 'ICMP'
          },
          6: {
            name: 'TCP'
          },
          17: {
            name: 'UDP'
          }
        },

        CONN_CAP_STATUS: {
          0: {
            name: this.isRuLocale ? 'Закрытый' : 'Closed'
          },
          1: {
            name: this.isRuLocale ? 'Открытый' : 'Open'
          },
          2: {
            name: this.isRuLocale ? 'Неизвестно' : 'Unknown'
          }
        },

        LINK_STATUS: {
          0: {
            name: 'None'
          },
          1: {
            name: 'Link up'
          },
          2: {
            name: 'Link down'
          },
          3: {
            name: 'Link in test state'
          }
        },

        OPER_CLASSES: {
          81: {
            name: '1-13'
          },
          82: {
            name: '14'
          },
          83: {
            name: '1-9, HT40+'
          },
          84: {
            name: '5-13, HT40-'
          },
          115: {
            name: '36-48, HT20'
          },
          116: {
            name: '36-44, HT40+'
          },
          117: {
            name: '40-48, HT40-'
          },
          118: {
            name: '52-64, HT20'
          },
          119: {
            name: '52-60, HT40+'
          },
          120: {
            name: '56-64, HT40-'
          },
          121: {
            name: '100-140, HT20'
          },
          122: {
            name: '100-132, HT40+'
          },
          123: {
            name: '104-136, HT40-'
          },
          124: {
            name: '149-161, HT20'
          },
          125: {
            name: '165-169, HT20'
          },
          126: {
            name: '149-157, HT40+'
          },
          127: {
            name: '153-161, HT40'
          },
          128: {
            name: 'VHT80'
          },
          129: {
            name: 'VHT160'
          },
          130: {
            name: 'VHT80+80'
          }
        }
      };
    }
  }
};

export default listsForHotspotMixin;
